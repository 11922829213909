import React, { ComponentProps } from 'react'

import { ReportCta } from '@/pages/report/components/ReportCta'
import { CTAS } from '@/pages/report/constants'
import { useReport } from '@/reports/hooks/useReport'

type Props = Omit<ComponentProps<typeof ReportCta>, 'title' | 'description' | 'buttonText' | 'analytics' | 'isLoading'>

export const ReportDiagnosticsCta = ({ ...props }: Props) => {
  const { data, isLoading } = useReport()

  const report = data?.data.report
  const isPaid = Boolean(report?.isPaid)

  return (
    <ReportCta
      title={CTAS.diagnostics.title}
      description={CTAS.diagnostics.description}
      buttonText={CTAS.diagnostics.button(data?.data.price || '$9')}
      analytics={{
        name: 'report_diagnostics_cta',
        reportId: report?.id || null,
        metricCategoryId: null,
        metricId: null,
        isPaid,
      }}
      isLoading={isLoading}
      {...props}
    />
  )
}

import { MetricCategoryId } from 'types'

import { ReportTabId } from '@/router/routes'

export const JUICE_METER_ANIMATION_DELAY = 0
export const JUICE_METER_ANIMATION_DURATION = 2000

// Disable animations in development
export const ANIMATIONS_ENABLED = import.meta.env.MODE !== 'development'

export const TESTIMONIAL_INDICES = {
  [ReportTabId.OVERVIEW]: [0, 1, 2],
  [ReportTabId.PERFORMANCE]: {
    summary: [0, 1, 2, 3, 4], // these are just for the avatars so we can reuse the quotes elsewhere
    metrics: [3],
  },
  [ReportTabId.CONVERSION]: {
    summary: [4],
    metrics: [5],
  },
  [ReportTabId.SEO]: {
    summary: [6],
    metrics: [7],
  },
  [ReportTabId.ACCESSIBILITY]: {
    summary: [8],
    metrics: [9],
  },
  [ReportTabId.BEST_PRACTICES]: {
    summary: [10],
    metrics: [11],
  },
  [ReportTabId.DIAGNOSTICS]: [12],
}

export const CTAS = {
  overview: {
    title: 'Ready to Unlock Full Insights?',
    description:
      'Get the complete report, with startup-tailored insights designed to boost performance, SEO, accessibility, and conversions.',
    button: (price: string) => `Purchase Full Report for ${price}`,
  },
  [MetricCategoryId.PERFORMANCE]: {
    summary: {
      title: 'Maximize Your Site’s Performance',
      description: 'Get deeper insights that drive engagement and conversion improvements beyond just speed.',
      button: (price: string) => `Unlock Full Report for ${price}`,
    },
    metrics: {
      title: 'Uncover More Performance Insights',
      description: (obfuscatedMetricCount: number) =>
        `Unlock ${obfuscatedMetricCount} additional opportunities to optimize performance and outrank competitors.`,
      button: (price: string) => `Get Full Report for ${price}`,
    },
  },
  [MetricCategoryId.CONVERSION]: {
    summary: {
      title: 'Convert More Visitors into Customers',
      description:
        'Access the full range of recommendations for optimizing calls-to-action, trust signals, and conversion pathways.',
      button: (price: string) => `Unlock Full Report for ${price}`,
    },
    metrics: {
      title: 'Enhance Your Conversion Potential',
      description: (obfuscatedMetricCount: number) =>
        `Unlock ${obfuscatedMetricCount} more high-impact conversion insights to improve visitor engagement and sales.`,
      button: (price: string) => `Get Full Report for ${price}`,
    },
  },
  [MetricCategoryId.SEO]: {
    summary: {
      title: 'Boost Your Search Visibility',
      description: 'Unlock advanced SEO insights tailored to increase visibility, clicks, and rankings.',
      button: (price: string) => `Unlock Full Report for ${price}`,
    },
    metrics: {
      title: 'Gain More SEO Insights',
      description: (obfuscatedMetricCount: number) =>
        `Unlock ${obfuscatedMetricCount} additional tips to maximize your search reach and improve rankings.`,
      button: (price: string) => `Get Full Report for ${price}`,
    },
  },
  [MetricCategoryId.ACCESSIBILITY]: {
    summary: {
      title: 'Ensure Your Site is Accessible to All',
      description:
        'Get comprehensive insights to make your site more usable, compliant, and inclusive for all visitors.',
      button: (price: string) => `Unlock Full Report for ${price}`,
    },
    metrics: {
      title: 'Expand Accessibility Insights',
      description: (obfuscatedMetricCount: number) =>
        `See ${obfuscatedMetricCount} more ways to make your site welcoming to all visitors.`,
      button: (price: string) => `Get Full Report for ${price}`,
    },
  },
  [MetricCategoryId.BEST_PRACTICES]: {
    summary: {
      title: 'Bring Your Site to the Next Level',
      description: 'Access complete best practices for modern web standards and exceptional user experience.',
      button: (price: string) => `Unlock Full Report for ${price}`,
    },
    metrics: {
      title: 'Discover More Best Practices',
      description: (obfuscatedMetricCount: number) =>
        `Access ${obfuscatedMetricCount} additional tips to enhance usability and professionalism.`,
      button: (price: string) => `Get Full Report for ${price}`,
    },
  },
  diagnostics: {
    title: 'Get the Full Diagnostic Picture',
    description:
      'Dive deeper into site diagnostics for insights into tech compatibility, performance, and optimizations.',
    button: (price: string) => `Unlock Full Report for ${price}`,
  },
}

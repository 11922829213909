import { z } from 'zod'

import { CategoryIdSchema, SubcategoryIdSchema } from '../categories'
import { CriticalRequestChainsAuditSchema } from '../lighthouseAudit/criticalRequestChainsAuditSchema'
import {
  AccesskeysAuditSchema,
  AriaAllowedAttrAuditSchema,
  AriaAllowedRoleAuditSchema,
  AriaCommandNameAuditSchema,
  AriaConditionalAttrAuditSchema,
  AriaDeprecatedRoleAuditSchema,
  AriaDialogNameAuditSchema,
  AriaHiddenBodyAuditSchema,
  AriaHiddenFocusAuditSchema,
  AriaInputFieldNameAuditSchema,
  AriaMeterNameAuditSchema,
  AriaProgressbarNameAuditSchema,
  AriaProhibitedAttrAuditSchema,
  AriaRequiredAttrAuditSchema,
  AriaRequiredChildrenAuditSchema,
  AriaRequiredParentAuditSchema,
  AriaRolesAuditSchema,
  AriaTextAuditSchema,
  AriaToggleFieldNameAuditSchema,
  AriaTooltipNameAuditSchema,
  AriaTreeitemNameAuditSchema,
  AriaValidAttrAuditSchema,
  AriaValidAttrValueAuditSchema,
  BfCacheAuditSchema,
  BootupTimeAuditSchema,
  ButtonNameAuditSchema,
  BypassAuditSchema,
  CanonicalAuditSchema,
  CharsetAuditSchema,
  ColorContrastAuditSchema,
  CrawlableAnchorsAuditSchema,
  CspXssAuditSchema,
  CumulativeLayoutShiftAuditSchema,
  DefinitionListAuditSchema,
  DeprecationsAuditSchema,
  DiagnosticsAuditSchema,
  DlitemAuditSchema,
  DoctypeAuditSchema,
  DocumentTitleAuditSchema,
  DomSizeAuditSchema,
  DuplicatedJavaScriptAuditSchema,
  DuplicateIdAriaAuditSchema,
  EfficientAnimatedContentAuditSchema,
  EmptyHeadingAuditSchema,
  ErrorsInConsoleAuditSchema,
  FirstContentfulPaintAuditSchema,
  FontDisplayAuditSchema,
  FontSizeAuditSchema,
  FormFieldMultipleLabelsAuditSchema,
  FrameTitleAuditSchema,
  GeolocationOnStartAuditSchema,
  HeadingOrderAuditSchema,
  HreflangAuditSchema,
  HtmlHasLangAuditSchema,
  HtmlLangValidAuditSchema,
  HtmlXmlLangMismatchAuditSchema,
  HttpStatusCodeAuditSchema,
  IdenticalLinksSamePurposeAuditSchema,
  ImageAltAuditSchema,
  ImageAspectRatioAuditSchema,
  ImageRedundantAltAuditSchema,
  ImageSizeResponsiveAuditSchema,
  InputButtonNameAuditSchema,
  InputImageAltAuditSchema,
  InspectorIssuesAuditSchema,
  InteractiveAuditSchema,
  IsCrawlableAuditSchema,
  IsOnHttpsAuditSchema,
  LabelAuditSchema,
  LabelContentNameMismatchAuditSchema,
  LandmarkOneMainAuditSchema,
  LargestContentfulPaintAuditSchema,
  LayoutShiftsAuditSchema,
  LCPLazyLoadedAuditSchema,
  LegacyJavaScriptAuditSchema,
  LinkInTextBlockAuditSchema,
  LinkNameAuditSchema,
  LinkTextAuditSchema,
  ListAuditSchema,
  ListitemAuditSchema,
  LongTasksAuditSchema,
  MainThreadWorkBreakdownAuditSchema,
  MaxPotentialFIDAuditSchema,
  MetaDescriptionAuditSchema,
  MetaRefreshAuditSchema,
  MetaViewportAuditSchema,
  ModernImageFormatsAuditSchema,
  NetworkRequestsAuditSchema,
  NetworkRttAuditSchema,
  NetworkServerLatencyAuditSchema,
  NodeAuditSchema,
  NoDocumentWriteAuditSchema,
  NonCompositedAnimationsAuditSchema,
  NotificationOnStartAuditSchema,
  ObjectAltAuditSchema,
  OffscreenImagesAuditSchema,
  PastePreventingInputsAuditSchema,
  PrioritizeLCPImageAuditSchema,
  RedirectsAuditSchema,
  RedirectsHttpAuditSchema,
  RenderBlockingResourcesAuditSchema,
  RobotsTxtAuditSchema,
  SelectNameAuditSchema,
  ServerResponseTimeAuditSchema,
  SkipLinkAuditSchema,
  SpeedIndexAuditSchema,
  TabindexAuditSchema,
  TableDuplicateNameAuditSchema,
  TableFakeCaptionAuditSchema,
  TargetSizeAuditSchema,
  TdHasHeaderAuditSchema,
  TdHeadersAttrAuditSchema,
  ThHasDataCellsAuditSchema,
  ThirdPartyCookiesAuditSchema,
  ThirdPartyFacadesAuditSchema,
  ThirdPartySummaryAuditSchema,
  TotalBlockingTimeAuditSchema,
  TotalByteWeightAuditSchema,
  UnminifiedCssAuditSchema,
  UnminifiedJavaScriptAuditSchema,
  UnsizedImagesAuditSchema,
  UnusedCssRulesAuditSchema,
  UnusedJavaScriptAuditSchema,
  UsesHttp2AuditSchema,
  UsesLongCacheTtlAuditSchema,
  UsesOptimizedImagesAuditSchema,
  UsesPassiveEventListenersAuditSchema,
  UsesRelPreconnectAuditSchema,
  UsesResponsiveImagesAuditSchema,
  UsesTextCompressionAuditSchema,
  ValidLangAuditSchema,
  ValidSourceMapsAuditSchema,
  VideoCaptionAuditSchema,
  ViewportAuditSchema,
} from '../lighthouseAudit/schema'
import { MetricCategoryId, MetricCategoryIdSchema } from '../metricCategories'
import { MetricId, MetricIdSchema } from '../metrics'

const MetricsSchema = z.object({
  [MetricId.ACCESSKEYS]: AccesskeysAuditSchema,
  [MetricId.ARIA_ALLOWED_ATTR]: AriaAllowedAttrAuditSchema,
  [MetricId.ARIA_ALLOWED_ROLE]: AriaAllowedRoleAuditSchema,
  [MetricId.ARIA_COMMAND_NAME]: AriaCommandNameAuditSchema,
  [MetricId.ARIA_CONDITIONAL_ATTR]: AriaConditionalAttrAuditSchema,
  [MetricId.ARIA_DEPRECATED_ROLE]: AriaDeprecatedRoleAuditSchema,
  [MetricId.ARIA_DIALOG_NAME]: AriaDialogNameAuditSchema,
  [MetricId.ARIA_HIDDEN_BODY]: AriaHiddenBodyAuditSchema,
  [MetricId.ARIA_HIDDEN_FOCUS]: AriaHiddenFocusAuditSchema,
  [MetricId.ARIA_INPUT_FIELD_NAME]: AriaInputFieldNameAuditSchema,
  [MetricId.ARIA_METER_NAME]: AriaMeterNameAuditSchema,
  [MetricId.ARIA_PROGRESSBAR_NAME]: AriaProgressbarNameAuditSchema,
  [MetricId.ARIA_PROHIBITED_ATTR]: AriaProhibitedAttrAuditSchema,
  [MetricId.ARIA_REQUIRED_ATTR]: AriaRequiredAttrAuditSchema,
  [MetricId.ARIA_REQUIRED_CHILDREN]: AriaRequiredChildrenAuditSchema,
  [MetricId.ARIA_REQUIRED_PARENT]: AriaRequiredParentAuditSchema,
  [MetricId.ARIA_ROLES]: AriaRolesAuditSchema,
  [MetricId.ARIA_TEXT]: AriaTextAuditSchema,
  [MetricId.ARIA_TOGGLE_FIELD_NAME]: AriaToggleFieldNameAuditSchema,
  [MetricId.ARIA_TOOLTIP_NAME]: AriaTooltipNameAuditSchema,
  [MetricId.ARIA_TREEITEM_NAME]: AriaTreeitemNameAuditSchema,
  [MetricId.ARIA_VALID_ATTR]: AriaValidAttrAuditSchema,
  [MetricId.ARIA_VALID_ATTR_VALUE]: AriaValidAttrValueAuditSchema,
  [MetricId.BF_CACHE]: BfCacheAuditSchema,
  [MetricId.BOOTUP_TIME]: BootupTimeAuditSchema,
  [MetricId.BUTTON_NAME]: ButtonNameAuditSchema,
  [MetricId.BYPASS]: BypassAuditSchema,
  [MetricId.CANONICAL]: CanonicalAuditSchema,
  [MetricId.CHARSET]: CharsetAuditSchema,
  [MetricId.COLOR_CONTRAST]: ColorContrastAuditSchema,
  [MetricId.CRAWLABLE_ANCHORS]: CrawlableAnchorsAuditSchema,
  [MetricId.CRITICAL_REQUEST_CHAINS]: CriticalRequestChainsAuditSchema,
  [MetricId.CSP_XSS]: CspXssAuditSchema,
  [MetricId.CUMULATIVE_LAYOUT_SHIFT]: CumulativeLayoutShiftAuditSchema,
  [MetricId.DEFINITION_LIST]: DefinitionListAuditSchema,
  [MetricId.DEPRECATIONS]: DeprecationsAuditSchema,
  [MetricId.DIAGNOSTICS]: DiagnosticsAuditSchema,
  [MetricId.DLITEM]: DlitemAuditSchema,
  [MetricId.DOCUMENT_TITLE]: DocumentTitleAuditSchema,
  [MetricId.DOCTYPE]: DoctypeAuditSchema,
  [MetricId.DOM_SIZE]: DomSizeAuditSchema,
  [MetricId.DUPLICATED_JAVASCRIPT]: DuplicatedJavaScriptAuditSchema,
  [MetricId.DUPLICATE_ID_ARIA]: DuplicateIdAriaAuditSchema,
  [MetricId.EFFICIENT_ANIMATED_CONTENT]: EfficientAnimatedContentAuditSchema,
  [MetricId.EMPTY_HEADING]: EmptyHeadingAuditSchema,
  [MetricId.ERRORS_IN_CONSOLE]: ErrorsInConsoleAuditSchema,
  [MetricId.FIRST_CONTENTFUL_PAINT]: FirstContentfulPaintAuditSchema,
  [MetricId.FONT_DISPLAY]: FontDisplayAuditSchema,
  [MetricId.FONT_SIZE]: FontSizeAuditSchema,
  [MetricId.FORM_FIELD_MULTIPLE_LABELS]: FormFieldMultipleLabelsAuditSchema,
  [MetricId.FRAME_TITLE]: FrameTitleAuditSchema,
  [MetricId.GEOLOCATION_ON_START]: GeolocationOnStartAuditSchema,
  [MetricId.HEADING_ORDER]: HeadingOrderAuditSchema,
  [MetricId.HREFLANG]: HreflangAuditSchema,
  [MetricId.HTML_HAS_LANG]: HtmlHasLangAuditSchema,
  [MetricId.HTML_LANG_VALID]: HtmlLangValidAuditSchema,
  [MetricId.HTML_XML_LANG_MISMATCH]: HtmlXmlLangMismatchAuditSchema,
  [MetricId.HTTP_STATUS_CODE]: HttpStatusCodeAuditSchema,
  [MetricId.IDENTICAL_LINKS_SAME_PURPOSE]: IdenticalLinksSamePurposeAuditSchema,
  [MetricId.IMAGE_ALT]: ImageAltAuditSchema,
  [MetricId.IMAGE_ASPECT_RATIO]: ImageAspectRatioAuditSchema,
  [MetricId.IMAGE_REDUNDANT_ALT]: ImageRedundantAltAuditSchema,
  [MetricId.IMAGE_SIZE_RESPONSIVE]: ImageSizeResponsiveAuditSchema,
  [MetricId.INPUT_BUTTON_NAME]: InputButtonNameAuditSchema,
  [MetricId.INPUT_IMAGE_ALT]: InputImageAltAuditSchema,
  [MetricId.INSPECTOR_ISSUES]: InspectorIssuesAuditSchema,
  [MetricId.INTERACTIVE]: InteractiveAuditSchema,
  [MetricId.IS_CRAWLABLE]: IsCrawlableAuditSchema,
  [MetricId.IS_ON_HTTPS]: IsOnHttpsAuditSchema,
  [MetricId.LABEL_CONTENT_NAME_MISMATCH]: LabelContentNameMismatchAuditSchema,
  [MetricId.LABEL]: LabelAuditSchema,
  [MetricId.LANDMARK_ONE_MAIN]: LandmarkOneMainAuditSchema,
  [MetricId.LARGEST_CONTENTFUL_PAINT]: LargestContentfulPaintAuditSchema,
  [MetricId.LAYOUT_SHIFTS]: LayoutShiftsAuditSchema,
  [MetricId.LCP_LAZY_LOADED]: LCPLazyLoadedAuditSchema,
  [MetricId.LEGACY_JAVASCRIPT]: LegacyJavaScriptAuditSchema,
  [MetricId.LINK_IN_TEXT_BLOCK]: LinkInTextBlockAuditSchema,
  [MetricId.LINK_NAME]: LinkNameAuditSchema,
  [MetricId.LINK_TEXT]: LinkTextAuditSchema,
  [MetricId.LIST]: ListAuditSchema,
  [MetricId.LISTITEM]: ListitemAuditSchema,
  [MetricId.LONG_TASKS]: LongTasksAuditSchema,
  [MetricId.MAIN_THREAD_WORK_BREAKDOWN]: MainThreadWorkBreakdownAuditSchema,
  [MetricId.MAX_POTENTIAL_FID]: MaxPotentialFIDAuditSchema,
  [MetricId.META_DESCRIPTION]: MetaDescriptionAuditSchema,
  [MetricId.META_REFRESH]: MetaRefreshAuditSchema,
  [MetricId.META_VIEWPORT]: MetaViewportAuditSchema,
  [MetricId.MODERN_IMAGE_FORMATS]: ModernImageFormatsAuditSchema,
  [MetricId.NETWORK_REQUESTS]: NetworkRequestsAuditSchema,
  [MetricId.NETWORK_RTT]: NetworkRttAuditSchema,
  [MetricId.NETWORK_SERVER_LATENCY]: NetworkServerLatencyAuditSchema,
  [MetricId.NO_DOCUMENT_WRITE]: NoDocumentWriteAuditSchema,
  [MetricId.NON_COMPOSITED_ANIMATIONS]: NonCompositedAnimationsAuditSchema,
  [MetricId.NOTIFICATION_ON_START]: NotificationOnStartAuditSchema,
  [MetricId.OBJECT_ALT]: ObjectAltAuditSchema,
  [MetricId.OFFSCREEN_IMAGES]: OffscreenImagesAuditSchema,
  [MetricId.PASTE_PREVENTING_INPUTS]: PastePreventingInputsAuditSchema,
  [MetricId.PRIORITIZE_LCP_IMAGE]: PrioritizeLCPImageAuditSchema,
  [MetricId.REDIRECTS_HTTP]: RedirectsHttpAuditSchema,
  [MetricId.REDIRECTS]: RedirectsAuditSchema,
  [MetricId.RENDER_BLOCKING_RESOURCES]: RenderBlockingResourcesAuditSchema,
  [MetricId.ROBOTS_TXT]: RobotsTxtAuditSchema,
  [MetricId.SELECT_NAME]: SelectNameAuditSchema,
  [MetricId.SERVER_RESPONSE_TIME]: ServerResponseTimeAuditSchema,
  [MetricId.SKIP_LINK]: SkipLinkAuditSchema,
  [MetricId.SPEED_INDEX]: SpeedIndexAuditSchema,
  [MetricId.TABINDEX]: TabindexAuditSchema,
  [MetricId.TABLE_DUPLICATE_NAME]: TableDuplicateNameAuditSchema,
  [MetricId.TABLE_FAKE_CAPTION]: TableFakeCaptionAuditSchema,
  [MetricId.TARGET_SIZE]: TargetSizeAuditSchema,
  [MetricId.TD_HAS_HEADER]: TdHasHeaderAuditSchema,
  [MetricId.TD_HEADERS_ATTR]: TdHeadersAttrAuditSchema,
  [MetricId.TH_HAS_DATA_CELLS]: ThHasDataCellsAuditSchema,
  [MetricId.THIRD_PARTY_COOKIES]: ThirdPartyCookiesAuditSchema,
  [MetricId.THIRD_PARTY_FACADES]: ThirdPartyFacadesAuditSchema,
  [MetricId.THIRD_PARTY_SUMMARY]: ThirdPartySummaryAuditSchema,
  [MetricId.TOTAL_BLOCKING_TIME]: TotalBlockingTimeAuditSchema,
  [MetricId.TOTAL_BYTE_WEIGHT]: TotalByteWeightAuditSchema,
  [MetricId.UNMINIFIED_CSS]: UnminifiedCssAuditSchema,
  [MetricId.UNMINIFIED_JAVASCRIPT]: UnminifiedJavaScriptAuditSchema,
  [MetricId.UNUSED_CSS_RULES]: UnusedCssRulesAuditSchema,
  [MetricId.UNSIZED_IMAGES]: UnsizedImagesAuditSchema,
  [MetricId.UNUSED_JAVASCRIPT]: UnusedJavaScriptAuditSchema,
  [MetricId.USES_HTTP2]: UsesHttp2AuditSchema,
  [MetricId.USES_LONG_CACHE_TTL]: UsesLongCacheTtlAuditSchema,
  [MetricId.USES_OPTIMIZED_IMAGES]: UsesOptimizedImagesAuditSchema,
  [MetricId.USES_PASSIVE_EVENT_LISTENERS]: UsesPassiveEventListenersAuditSchema,
  [MetricId.USES_REL_PRECONNECT]: UsesRelPreconnectAuditSchema,
  [MetricId.USES_RESPONSIVE_IMAGES]: UsesResponsiveImagesAuditSchema,
  [MetricId.USES_TEXT_COMPRESSION]: UsesTextCompressionAuditSchema,
  [MetricId.VALID_LANG]: ValidLangAuditSchema,
  [MetricId.VALID_SOURCE_MAPS]: ValidSourceMapsAuditSchema,
  [MetricId.VIDEO_CAPTION]: VideoCaptionAuditSchema,
  [MetricId.VIEWPORT]: ViewportAuditSchema,
})

const BaseCustomMetricSchema = z.object({
  score: z.number().nullable(),
  scoreDisplayMode: z.union([z.literal('binary'), z.literal('numeric')]),
})

const CtaSchema = z.object({
  node: NodeAuditSchema,
  isVisibleInViewport: z.boolean(),
})

export type Cta = z.infer<typeof CtaSchema>

const CtaVisibleMetricSchema = BaseCustomMetricSchema.extend({
  id: z.literal(MetricId.CTA_VISIBLE),
  details: z.object({
    // we only store the first cta with this metric, all other cta metrics should reference this one
    items: z.array(CtaSchema),
  }),
})

const CtaQualityMetricSchema = BaseCustomMetricSchema.extend({
  id: z.literal(MetricId.CTA_QUALITY),
  details: z.object({
    items: z.array(
      z.object({
        node: NodeAuditSchema.nullable(),
        clarityScore: z.number().nullable(),
        urgencyScore: z.number().nullable(),
        relevanceScore: z.number().nullable(),
        suggestions: z.array(z.string()),
      }),
    ),
  }),
})

const CtaLengthMetricSchema = BaseCustomMetricSchema.extend({
  id: z.literal(MetricId.CTA_LENGTH),
  details: z.object({
    items: z.array(
      z.object({
        node: NodeAuditSchema.nullable(),
        wordCount: z.number(),
        charCount: z.number(),
      }),
    ),
  }),
})

const CtaMetricsSchema = z.object({
  [MetricId.CTA_VISIBLE]: CtaVisibleMetricSchema,
  [MetricId.CTA_QUALITY]: CtaQualityMetricSchema,
  [MetricId.CTA_LENGTH]: CtaLengthMetricSchema,
})

export type CtaMetrics = z.infer<typeof CtaMetricsSchema>

const FormSchema = z.object({
  node: NodeAuditSchema,
  fields: z.array(z.object({ node: NodeAuditSchema })),
  fieldCount: z.number(),
})

export type Form = z.infer<typeof FormSchema>

const FormFieldCountMetricSchema = BaseCustomMetricSchema.extend({
  id: z.literal(MetricId.FORM_FIELD_COUNT),
  details: z.object({
    items: z.array(FormSchema),
  }),
})

const FormMetricsSchema = z.object({
  [MetricId.FORM_FIELD_COUNT]: FormFieldCountMetricSchema,
})

export type FormMetrics = z.infer<typeof FormMetricsSchema>

const KeywordAnalysisSchema = z.object({
  keyword: z.string(),
  presence: z.object({
    title: z.boolean(),
    metaDescription: z.boolean(),
    h1: z.boolean(),
    h2: z.boolean(),
    body: z.boolean(),
  }),
  density: z.number(),
})

export type KeywordAnalysis = z.infer<typeof KeywordAnalysisSchema>

const KeywordOptimizationMetricSchema = BaseCustomMetricSchema.extend({
  id: z.literal(MetricId.KEYWORD_OPTIMIZATION),
  details: z.object({
    items: z.array(KeywordAnalysisSchema),
  }),
})

const KeywordMetricsSchema = z.object({
  [MetricId.KEYWORD_OPTIMIZATION]: KeywordOptimizationMetricSchema,
})

export type KeywordMetrics = z.infer<typeof KeywordMetricsSchema>

export const SocialProofAnalysisSchema = z.object({
  socialProofDetected: z.boolean(),
  types: z.object({
    reviews: z.boolean(),
    testimonials: z.boolean(),
    starRatings: z.boolean(),
    endorsements: z.boolean(),
  }),
  summary: z.object({
    overallSentiment: z.enum(['positive', 'neutral', 'negative']),
    keyPhrases: z.array(z.string()),
  }),
})

export type SocialProofAnalysis = z.infer<typeof SocialProofAnalysisSchema>

const SocialProofMetricSchema = BaseCustomMetricSchema.extend({
  id: z.literal(MetricId.SOCIAL_PROOF),
  details: z.object({
    items: z.array(SocialProofAnalysisSchema),
  }),
})

const SocialProofMetricsSchema = z.object({
  [MetricId.SOCIAL_PROOF]: SocialProofMetricSchema,
})

export type SocialProofMetrics = z.infer<typeof SocialProofMetricsSchema>

const AverageLineSpacingMetricSchema = BaseCustomMetricSchema.extend({
  id: z.literal(MetricId.LINE_SPACING),
  numericValue: z.number().nullable(),
  numericUnit: z.literal('unitless'),
})

const UXMetricsSchema = z.object({
  [MetricId.LINE_SPACING]: AverageLineSpacingMetricSchema,
})

export type UXMetrics = z.infer<typeof UXMetricsSchema>

const MetaTitleLengthMetricSchema = BaseCustomMetricSchema.extend({
  id: z.literal(MetricId.META_TITLE_LENGTH),
  details: z.object({
    items: z.array(
      z.object({
        metaTitle: z.string().nullable(),
        length: z.number(),
      }),
    ),
  }),
})

const MetaDescriptionLengthMetricSchema = BaseCustomMetricSchema.extend({
  id: z.literal(MetricId.META_DESCRIPTION_LENGTH),
  details: z.object({
    items: z.array(
      z.object({
        metaDescription: z.string().nullable(),
        length: z.number(),
      }),
    ),
  }),
})

const MetaMetricsSchema = z.object({
  [MetricId.META_TITLE_LENGTH]: MetaTitleLengthMetricSchema,
  [MetricId.META_DESCRIPTION_LENGTH]: MetaDescriptionLengthMetricSchema,
})

export type MetaMetrics = z.infer<typeof MetaMetricsSchema>

const CustomMetricsSchema = CtaMetricsSchema.merge(FormMetricsSchema)
  .merge(KeywordMetricsSchema)
  .merge(SocialProofMetricsSchema)
  .merge(UXMetricsSchema)
  .merge(MetaMetricsSchema)

export type CustomMetrics = z.infer<typeof CustomMetricsSchema>

const MetricCategoryScoresSchema = z.object({
  [MetricCategoryId.PERFORMANCE]: z.number(),
  [MetricCategoryId.ACCESSIBILITY]: z.number(),
  [MetricCategoryId.SEO]: z.number(),
  [MetricCategoryId.BEST_PRACTICES]: z.number(),
  [MetricCategoryId.CONVERSION]: z.number(),
})

export type MetricCategoryScores = z.infer<typeof MetricCategoryScoresSchema>

export type ReportTechStack = 'react' | 'next' | 'remix' | 'vue' | 'tailwindcss' | string

const TechStackSchema = z.array(z.union([z.enum(['react', 'next', 'remix', 'vue', 'tailwindcss']), z.string()]))

const CompetitorSchema = z.object({
  rank: z.number(),
  url: z.string().nullable(),
  overallScore: z.number().nullable(),
  metricCategoryScores: z.record(MetricCategoryIdSchema, z.number().nullable()),
  isObfuscated: z.boolean().optional(),
})

export const ReportDocumentSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  url: z.string(),
  title: z.string(),
  description: z.string(),
  category: CategoryIdSchema,
  subcategory: SubcategoryIdSchema,
  techStack: TechStackSchema,
  keywords: z.array(z.string()),
  metrics: MetricsSchema.merge(CustomMetricsSchema),
  metricCategoryScores: MetricCategoryScoresSchema,
  overallScore: z.number(),
  config: z.object({
    appVersion: z.string(),
    lighthouseVersion: z.string(),
    axeVersion: z.string(),
    throttling: z.object({
      rttMs: z.number(),
      throughputKbps: z.number(),
      requestLatencyMs: z.number(),
      downloadThroughputKbps: z.number(),
      uploadThroughputKbps: z.number(),
      cpuSlowdownMultiplier: z.number(),
    }),
    screenEmulation: z.object({
      deviceScaleFactor: z.number(),
      mobile: z.boolean(),
      width: z.number(),
      height: z.number(),
    }),
  }),
  competitors: z.object({
    avgOverallScore: z.number(),
    avgMetricCategoryScores: MetricCategoryScoresSchema,
    avgMetricScores: z.record(MetricIdSchema, z.number().nullable()),
    items: z.array(CompetitorSchema),
  }),
  screenshotUrl: z.string(),
  isLatest: z.boolean(),
  isPaid: z.boolean(),
  paymentId: z.string().optional(),
})

export type ReportDocument = z.infer<typeof ReportDocumentSchema>

// NOTE: If TS shows an error here, you probably need to add the metric id to the report document schema
export type ReportMetric<T extends MetricId> = ReportDocument['metrics'][T]
